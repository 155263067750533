import { parse } from "cookie";
import { useRouter } from "next/router";
import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import config from "../config";
import { ClientUserData } from "../types";

export const DefaultContextValue = { isAuthenticated: false };
export const UserContext =
  React.createContext<ClientUserData>(DefaultContextValue);

export const useUser = () => {
  const context = useContext(UserContext);
  return context;
};

type Props = {
  children?: ReactNode;
};

export const UserContextProvider: FC<Props> = ({ children }) => {
  const router = useRouter();
  const [data, setData] = useState<ClientUserData>(DefaultContextValue);

  useEffect(() => {
    const cookies = parse(window.document.cookie);
    setData({
      isAuthenticated: cookies[config.cookies.UserDataCookieKey] === "1",
    });
  }, [router.pathname]);

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};
