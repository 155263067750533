import { useCallback, useState } from "react";
import SystemMessage, { SystemMessageProps } from "@/components/SystemMessage";
import { BodyPortal } from "@/components/Misc/BodyPortal";

export interface FixedSystemMessage {
  props: SystemMessageProps;
  text: string;
  dismissAfterSeconds?: number;
}

interface FixedSystemMessageWithTimerId extends FixedSystemMessage {
  timerId: number;
}

type FixedSystemMessageOptions = {
  /**
   * @default 0
   */
  offsetBottom?: number;
};

export function useSystemMessages(options?: {
  fixedOptions?: FixedSystemMessageOptions;
}) {
  const [systemMessages, setSystemMessages] = useState<
    FixedSystemMessageWithTimerId[]
  >([]);

  function clearSystemMessages() {
    setSystemMessages([]);
  }

  const addSystemMessage = useCallback(function (message: FixedSystemMessage) {
    setSystemMessages((prev) => {
      const isAlreadyInList = prev.some((x) => message.text === x.text);
      if (isAlreadyInList) {
        return prev;
      }

      let timerId = 0;
      if (message.dismissAfterSeconds !== undefined) {
        const dismissAfterMillis = message.dismissAfterSeconds * 1000;
        timerId = window.setTimeout(() => {
          setSystemMessages((delayedPrev) => {
            const filteredMessages = delayedPrev.filter(
              (existing) => existing.timerId !== timerId
            );
            return filteredMessages;
          });
        }, dismissAfterMillis);
      }

      return prev.concat({
        ...message,
        timerId: timerId,
      });
    });
  }, []);

  const addSuccessMessage = useCallback(
    (message: string) => {
      addSystemMessage({
        text: message,
        props: {
          kind: "success",
        },
        dismissAfterSeconds: 8,
      });
    },
    [addSystemMessage]
  );

  const addFailureMessage = useCallback(
    (message: string) => {
      addSystemMessage({
        text: message,
        props: {
          kind: "error",
        },
        dismissAfterSeconds: 8,
      });
    },
    [addSystemMessage]
  );

  function FixedSystemMessageList() {
    return (
      <>
        {hasSystemMessages && (
          <BodyPortal>
            <div
              className="fixed bottom-0 w-full z-50"
              style={{ bottom: options?.fixedOptions?.offsetBottom ?? 0 }}
            >
              <div className="flex flex-col m-md justify-center items-center">
                {systemMessages.map((message, index) => (
                  <div key={index} className="mt-sm border-grey-120">
                    <SystemMessage
                      key={index}
                      {...message.props}
                      className={"shadow-elevation-3"}
                    >
                      {message.text}
                    </SystemMessage>
                  </div>
                ))}
              </div>
            </div>
          </BodyPortal>
        )}
      </>
    );
  }

  const hasSystemMessages = systemMessages.length > 0;
  return {
    hasSystemMessages,
    systemMessages,
    FixedSystemMessageList,
    addSystemMessage,
    addSuccessMessage,
    addFailureMessage,
    clearSystemMessages,
  };
}
