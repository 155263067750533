import { mcn } from "../utils";
import Spinner, { SpinnerProps } from "./Spinner";

type Props = {
  spinnerProps?: SpinnerProps;
  isTransparent?: boolean;
  className?: string;
  loadingText?: string;
  position?: "fixed" | "absolute";
};

const SpinnerOverlay = (props: Props) => {
  const position = props.position ?? "fixed";
  return (
    <div
      className={mcn(
        position === "absolute" && "absolute",
        position === "fixed" && "fixed",
        "top-0 left-0 z-50",
        "w-full h-full",
        "flex flex-col items-center justify-center",
        props.isTransparent ? "bg-white opacity-80" : "bg-white",
        props.className
      )}
    >
      <Spinner {...props.spinnerProps} />
      {props.loadingText && (
        <h1 className="text-style-headline5 text-blue mt-xl">{props.loadingText}</h1>
      )}
    </div>
  );
};

export default SpinnerOverlay;
