import { IconActionClose } from "@/components/Icons";
import { mcn } from "@/utils/mergeClassNames";
import { Dialog as HeadlessuiDialog } from "@headlessui/react";
import { PropsWithChildren } from "react";
import Spinner from "../Spinner";

type Props = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
  isLoading?: boolean;
  /**
   * Disables the "X" button in the corner and prevents onClose from ever being called.
   */
  disableCloseByClickingOutside?: boolean;
  disableClose?: boolean;
}>;

export function Dialog(props: Props) {
  const onClose =
    props.disableClose || props.disableCloseByClickingOutside
      ? () => {}
      : props.onClose;
  return (
    <HeadlessuiDialog
      open={props.open}
      onClose={onClose}
      className="relative z-50"
    >
      <div className="fixed inset-0 h-full w-full bg-white bg-opacity-30" />
      <div className="fixed inset-0 h-full w-full overflow-y-scroll">
        <div className="flex justify-center items-center min-h-full">
          <div className="p-md w-full tablet:w-fit">
            <HeadlessuiDialog.Panel
              className={mcn(
                "shadow-elevation-3",
                "bg-white rounded-2 p-lg",
                "w-full tablet:w-[600px]",
                "relative"
              )}
            >
              {!props.disableClose && (
                <button
                  className={mcn(
                    "absolute right-xs top-xs",
                    "text-text-primary hover:bg-light-yellow ",
                    "focus-visible:default-focus",
                    "rounded-full p-2xs"
                  )}
                  onClick={props.onClose}
                >
                  <IconActionClose fontSize={24} />
                </button>
              )}

              {props.title && (
                <HeadlessuiDialog.Title className="text-style-headline4 text-center text-text-primary">
                  {props.title}
                </HeadlessuiDialog.Title>
              )}
              {(props.description || props.isLoading) && (
                <div className="min-h-[88px]">
                  {props.isLoading === true ? (
                    <div className="flex justify-center">
                      <Spinner width={40} classNameAnimation="m-xl" />
                    </div>
                  ) : (
                    <>
                      <HeadlessuiDialog.Description className="text-style-body2 mt-md">
                        {props.description}
                      </HeadlessuiDialog.Description>
                    </>
                  )}
                </div>
              )}
              {props.children}
            </HeadlessuiDialog.Panel>
          </div>
        </div>
      </div>
    </HeadlessuiDialog>
  );
}
