import { IconActionChevronDown } from "@/components/Icons";
import { mcn } from "@/utils/mergeClassNames";
import { Listbox, Transition } from "@headlessui/react";
import { Fragment, JSXElementConstructor, useState } from "react";
import Label, { LabelProps } from "../Label";
import { FieldState } from "./TextField";

const CLEAR_KEY = "clear_selection";

type OptionRenderPropArg = {
  active: boolean;
  selected: boolean;
  disabled: boolean;
};

export type DropdownItem<T> = {
  id: string | number | undefined;
  data: T;
  text?: string;
  disabled?: boolean;
  preSelected?: boolean;
};

type DropdownProps<T> = {
  id?: string;
  className?: string;
  state?: FieldState;
  labelProps?: LabelProps;
  items: DropdownItem<T>[];
  placeHolder?: string;
  texts?: {
    /**
     * @default Empty list
     */
    emptyList?: string;
  };
  onRenderItem?: (
    item: DropdownItem<T> & OptionRenderPropArg
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => React.ReactElement<any, string | JSXElementConstructor<any>>;
  onChange?: (item?: DropdownItem<T>) => void;
  /**
   * Clears selected item if any
   */
  hasClearItem?: boolean;
  disabled?: boolean;
};

function Dropdown<T>(props: DropdownProps<T>) {

  const items = props.items;

  const [selectedItem, setSelectedItem] = useState<DropdownItem<T> | undefined>(items.find(x => x.preSelected === true));

  const disabled: boolean = props.disabled ?? false;

  const currentState: FieldState = props.state ?? "neutral";

  const _setSelectedItem = (item: DropdownItem<T>) => {
    const selectedItem = item?.id === CLEAR_KEY ? undefined : item;

    setSelectedItem(selectedItem);

    if (props.onChange) {
      props.onChange(selectedItem);
    }
  };

  if (selectedItem && items?.length && props.hasClearItem) {
    items.unshift({
      id: CLEAR_KEY,
      text: "Clear selection",
    } as DropdownItem<T>);
  }

  return (
    <Listbox value={selectedItem} onChange={_setSelectedItem} disabled={disabled}>
      <div className={mcn("relative", props.className)}>
        {props.labelProps && (
          <Label
            {...props.labelProps}
            className={mcn(props.labelProps.className, "mb-1")}
          />
        )}
        <Listbox.Button
          className={mcn(
            "bg-white text-text-primary",
            "border rounded",
            "flex justify-between items-center",
            "w-full p-2",
            "input-default",
            "hover:input-hover",
            "active:input-active",
            "disabled:input-disabled",
            "focus-visible:input-focus",
            "min-h-[42px]", // Height correctrion when empty
            currentState !== "neutral" && `input-${currentState}`
          )}
        >
          <span
            className={mcn(
              "block truncate",
              !selectedItem && "text-grey-120" // placeholder text
            )}
          >
            {selectedItem?.text ?? props.placeHolder ?? ""}
          </span>
          <IconActionChevronDown className="text-text-primary" />
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in-out "
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={mcn(
              "absolute max-h-[200px] min-h-[36px] w-full overflow-auto mt-sx z-50",
              "rounded-1 bg-white py-2xs text-text-primary shadow-elevation-2 focus:outline-none sm:text-sm"
            )}
          >
            {items?.map((item) => (
              <Listbox.Option
                key={item.id}
                value={item}
                disabled={item.disabled}
                className={({ active, disabled, selected }) =>
                  mcn(
                    "relative cursor-pointer py-2 px-sm",
                    active && "bg-light-yellow text-black",
                    disabled && "bg-grey-10 text-grey-120 cursor-not-allowed",
                    selected && "text-style-subtitle1"
                  )
                }
              >
                {(optArg) => {
                  if (props.onRenderItem) {
                    return props.onRenderItem({ ...item, ...optArg });
                  }
                  return <span>{item.text}</span>;
                }}
              </Listbox.Option>
            )) ?? (
              <span className="relative cursor-pointer py-2 px-sm text-grey-120">
                {props.texts?.emptyList ?? "Empty list..."}
              </span>
            )}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

export default Dropdown;
