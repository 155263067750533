/*
 * Used to render components into the document body element instead of parent React element.
 * See: https://reactjs.org/docs/portals.html
 *  NOTE: MIGHT CAUSE ISSUES WITH SERVER SIDE RENDERING. USE WITH CAUTION.
 * */
import { ReactNode } from "react";
import { createPortal } from "react-dom";
import { isRunningInServerEnv } from "@/utils/isRunningInServerEnv";

export function BodyPortal(props: { children: ReactNode }) {
  if (isRunningInServerEnv()) {
    return null;
  }
  return createPortal(props.children, document.body);
}
