import {
  IconActionCheckAlt,
  IconActionCircle,
  IconActionRemove,
} from "@/components/Icons";
import { mcn } from "@/utils/mergeClassNames";
import { FieldState } from "./TextField";

export type FieldMessageData = { text: string; state: FieldState };

const FieldMessage = (props: { message: FieldMessageData, className?: string }) => {
  const color =
    (props.message.state === "error" && "text-red-120") ||
    (props.message.state === "success" && "text-green-120") ||
    "";
  const _className = mcn(`${color} flex flex-row items-center text-style-body2`, props.className);
  return (
    <span
      key={props.message.text}
      className={_className}
    >
      {props.message.state === "neutral" && (
        <IconActionCircle className="mr-xs flex-shrink-0" />
      )}
      {props.message.state === "success" && (
        <IconActionCheckAlt className="mr-xs flex-shrink-0" />
      )}
      {props.message.state === "error" && (
        <IconActionRemove className="mr-xs flex-shrink-0" />
      )}
      {props.message.text}
    </span>
  );
};

export default FieldMessage;
