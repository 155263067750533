import {
  CompetenceBaseTreeEx,
  LearningOpportunityName,
} from "@/server/backend-api";
import React, { FC, PropsWithChildren, useContext } from "react";
import { OrganisationBase } from "@/server/backend-api/organisation";
import { ContactFormSetting } from "@/server/backend-api/generated";
import { DUMMY_TREE } from "@/utils/competenceTree";

type BaseContext = {
  competenceTree: CompetenceBaseTreeEx;
  memberUniversities: OrganisationBase[];
  contactFormSettings: ContactFormSetting[];
  learningOppNames: LearningOpportunityName[];
};

const BaseContext = React.createContext<BaseContext>({
  competenceTree: DUMMY_TREE,
  memberUniversities: [],
  contactFormSettings: [],
  learningOppNames: [],
});

interface Props {
  competenceTree: CompetenceBaseTreeEx;
  memberUniversities: OrganisationBase[];
  contactFormSettings: ContactFormSetting[];
  learningOpps: LearningOpportunityName[];
}

export const BaseContextProvider: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <BaseContext.Provider
      value={{
        competenceTree: props.competenceTree,
        memberUniversities: props.memberUniversities,
        contactFormSettings: props.contactFormSettings,
        learningOppNames: props.learningOpps,
      }}
    >
      {props.children}
    </BaseContext.Provider>
  );
};

export const useBaseContext = () => {
  return useContext(BaseContext);
};
