import NextLink, { LinkProps } from "next/link";
import { FC, ReactNode } from "react";
import { mcn } from "../utils";

interface Props extends LinkProps {
  className?: string;
  children?: ReactNode;
}

const Link: FC<Props> = (props) => (
  <NextLink {...props} className={mcn("link", props.className)}>
    {props.children}
  </NextLink>
);

export default Link;
