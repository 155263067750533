import { mcn } from "@/utils/mergeClassNames";

export type SpinnerProps = {
  className?: string;
  classNameAnimation?: string;
  width?: number;
};

const height_to_width_ratio = 2.5;

const Spinner = (props: SpinnerProps) => {
  const width = props.width ?? 100;
  const height = width * height_to_width_ratio;
  return (
    <span className={props.className}>
      <div
        className={mcn(
          "animate-loader-pulse-body",
          "relative",
          "bg-yellow-100",
          props.classNameAnimation
        )}
        style={{ height, width }}
      >
        <div
          className={mcn(
            "animate-loader-pulse-outer",
            "absolute top-0 left-0 z-50",
            "h-full w-full",
            "bg-yellow-100/50"
          )}
        />
      </div>
    </span>
  );
};

export default Spinner;
