import { DetailedHTMLProps, LabelHTMLAttributes } from "react";
import { mcn } from "../utils";

export type LabelProps = { text?: string } & DetailedHTMLProps<
  LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;

const Label = ({ children, className, text, ...rest }: LabelProps) => {
  return (
    <label {...rest} className={mcn("inline-flex text-style-subtitle1", className)}>
      {text ?? children}
    </label>
  );
};

export default Label;
