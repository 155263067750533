import { useQuery } from "react-query";
import { Profile } from "../../types";
import { useUser } from "../UserContext";

export const useProfile = () => {
  const user = useUser();
  const query = useQuery<Profile>(
    ["profile", user.isAuthenticated],
    async () => {
      if (user.isAuthenticated) {
        return (await fetch("/api/profile")).json();
      } else {
        return Promise.resolve({ isAuthenticated: false, profile: null });
      }
    }
  );

  return {
    isAuthenticated: query.data
      ? query.data.isAuthenticated
      : user.isAuthenticated,
    profile: query.data?.profile ?? null,
  };
};
