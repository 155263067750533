import {
  IconActionCheckAlt,
  IconActionClose,
  IconActionWarning,
  IconIconInfo,
} from "@/components/Icons";
import { mcn } from "../utils";

export type SystemMessageKind = "error" | "warning" | "info" | "success";

export interface SystemMessageProps {
  kind?: SystemMessageKind;
  children?: React.ReactNode;
  className?: string;
  heading?: string;
}

const SystemMessage = (props: SystemMessageProps) => {
  let heading = props.heading;
  if (!heading) {
    if (props.kind === "error") {
      heading = "Error";
    } else if (props.kind === "warning") {
      heading = "Warning";
    }
  }
  return (
    <div
      className={mcn(
        "flex",
        !props.kind && "bg-grey-20 text-text-primary",
        props.kind === "error" && "bg-light-red text-text-primary",
        props.kind === "warning" && "bg-light-yellow text-text-primary",
        props.kind === "info" && "bg-light-blue text-white",
        props.kind === "success" && "bg-light-green text-blue",
        "p-md rounded-2",
        "border border-grey-40",
        props.className
      )}
    >
      <div>
        {props.kind === "error" && (
          <IconActionClose className="text-red-100 text-2xl mr-sm" />
        )}
        {props.kind === "info" && (
          <IconIconInfo className="text-white text-2xl mr-sm mt-2xs" />
        )}
        {props.kind === "warning" && (
          <IconActionWarning className="text-yellow-100 text-2xl mr-sm" />
        )}
        {props.kind === "success" && (
          <IconActionCheckAlt className="text-grey-160 text-2xl mr-sm mt-2xs" />
        )}
      </div>
      <div>
        <span className="block text-style-subtitle1 mb-2xs">{heading}</span>
        <p className="text-style-body2">{props.children}</p>
      </div>
    </div>
  );
};

export default SystemMessage;
